import React, { lazy } from 'react';
const LandingPage = lazy(() => import("views/LandingPage/LandingPage"));

const TestPage = React.lazy(() => import("views/Dev/TestPage"));

const publicRoutes = [
  { path: "/test/", name: "Test", component: TestPage, },
  {
    path: '/',
    name: 'Home',
    component: LandingPage
  },
];

const privateRoutes = [

]

export { privateRoutes, publicRoutes };


import { isMobile } from "Utils/Dimension";

const productStyle = {
  section: {
    paddingTop: isMobile ? 100 : '8rem',
    textAlign: "center"
  },
};

export default productStyle;

import {
  container,
  main,
  mainRaised
} from "assets/jss/material-kit-pro-react.jsx";
import { isMobile } from "Utils/Dimension";

const pagestyle = {
  content: {
    paddingTop: isMobile ? 100 : '8rem',
    minHeight: '100vh',
  },
  container: {
    ...container,
    zIndex: "2"
  },
  main: {
    ...main
  },
  mainRaised: {
    ...mainRaised,
  },
  mainRaisedNormal: {
    ...mainRaised,
    marginTop: 0,
  },
  space1: {
    paddingTop: isMobile ? 30 : 50
  },
  space2: {
    paddingTop: isMobile ? 20 : 30

  },
  space3: {
    paddingTop: isMobile ? 16 : 20
  },
  box: {
    boxShadow: '0 0 0 0px rgba(0,0,0,0.2), 0 9px 9px 1px rgba(0,0,0,.2)'
  }
};

export default pagestyle;
import { MuiThemeProvider } from "@material-ui/core/styles";
import theme from "assets/jss/MyTheme";
import "assets/scss/material-kit-pro-react.css";
import hist from "history.js";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Route, Router, Switch } from "react-router";
import { PersistGate } from "redux-persist/es/integration/react";
import { persistor, store } from "redux/store";
import Layout from "views/Layout/Layout";

ReactDOM.render(

  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <MuiThemeProvider theme={theme}>
        <Router history={hist}>
          <Switch>
            <Route path='*' component={Layout} />
          </Switch>
        </Router>
      </MuiThemeProvider>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);

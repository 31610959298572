import withStyles from "@material-ui/core/styles/withStyles";
import Button from "components/CustomButtons/Button";
import { default as React } from "react";
import { setCookieAllow } from "redux/reducers/appstate";
import { useDispatch } from 'react-redux'
const styles = {
  cookieContainer: {
    position: "fixed",
    bottom: 0,
    background: "#fff",
    padding: 20,
    boxShadow: "0 -1px 3px #eaf0f6",
    zIndex: 1000,
    left: 0,
    right: 0
  },
  cookieBody: {
    margin: "0 auto",
    maxWidth: 1000
  }
};
function CookieAllow(props) {

  const dispatch = useDispatch()
  const onDecline = () => {
    dispatch(setCookieAllow('no'));
  };
  const onAccept = () => {
    dispatch(setCookieAllow('yes'));
  };
  const { classes } = props;
  return (
    <div className={classes.cookieContainer}>
      <div className={classes.cookieBody}>
        <p>
          This website stores cookies on your computer. These cookies are used
          to collect information about how you interact with our website and
          allow us to remember you. We use this information in order to
          improve and customize your browsing experience and for analytics and
          metrics about our visitors both on this website and other media. To
          find out more about the cookies we use, see our Privacy Policy. If
          you decline, your information won’t be tracked when you visit this
          website. A single cookie will be used in your browser to remember
          your preference not to be tracked.
          </p>
        <Button color="info" size="sm" onClick={() => onAccept()}>
          Accept
          </Button>
        <Button
          color="danger"
          size="sm"
          simple
          onClick={() => onDecline()}
        >
          Decline
          </Button>
      </div>
    </div>
  );
}
export default (
  withStyles(styles, { withTheme: true })(CookieAllow)
);

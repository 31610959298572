import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from '@material-ui/core/ListItemText';
import withStyles from '@material-ui/core/styles/withStyles';
import BusinessCenter from "@material-ui/icons/BusinessCenter";
import Face from "@material-ui/icons/Face";
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import VisibilityIcon from '@material-ui/icons/Visibility';
import profileImage from "assets/img/default-avatar.png";
import commonStyles from "assets/jss/common/common";
import classNames from "classnames";
import Button from 'components/CustomButtons/Button';
import CustomDropdown from 'components/CustomDropdown/CustomDropdown.jsx';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import { CAPITAL_APP_URL } from "config";
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { getProfile, isLogin, logout } from "routes/Auth/Auth";

const styles = {
    ...commonStyles,
    topHeader: {
        backgroundColor: commonStyles.blackColor
    },
    tabs: {
        display: 'flex',
        alignItems: 'center'
    },
    links: {
        display: 'flex'
    },
    link: {
        paddingRight: 20,
        "& a": {
            color: 'white',
            fontWeight: 400
        },
        "& a:hover": {
            color: commonStyles.infoColor
        }
    },
    links_container: {
        justifyContent: 'flex-end'
    },
    imageDropdownButton: {
        padding: "0 10px",
    },
    profileImg: {
        height: '3rem',
        objectFit: "contain",
        paddingRight: '1rem',
        paddingLeft: '1rem',
        cursor: 'pointer'
    },
    darkBlue: {
        backgroundColor: commonStyles.blueDarkColor,
        transition: 'all 1s'
    },
    list: {
        width: 'max-content'
    },
    topHeaderButton: {
        margin: 0,
    }
};
const getProfileImage = (profile) => {
    if (!profile) return profileImage;
    let url = profile.image_url;
    return url ? profile.image_url : profileImage;
};
function TopHeader(props) {
    // const [index, setIndex] = useState(-1)
    const location = useLocation()
    const history = useHistory()
    const [menuIndex, setMenuIndex] = useState(0)

    const { classes } = props
    const pathname = location.pathname


    useEffect(() => {
        getSubMenu()
    }, [location])

    const getSubMenu = () => {

        if (pathname === '/') {
            setMenuIndex('home')
            return
        }
        if (pathname === '/invest/factor') {
            setMenuIndex('factor_investing')
            return
        }
        if (pathname === '/invest/thematic') {
            setMenuIndex('thematic_investing')
            return
        }

        if (pathname.includes('/investment/company/')) {
            setMenuIndex('company_deep_dive')
            return
        }
        setMenuIndex(-1)

    }

    const topHeaderClass = classNames({
        [classes.topHeader]: true,
        [classes.darkBlue]: menuIndex > -1
    })
    const onClicMenu = (menu) => {
        switch (menu) {
            case 'home':
                history.push('/')
                break;
            case 'thematic_investing':
                history.push('/invest/thematic')
                break;
            case 'factor_investing':
                history.push('/invest/factor')
                break;
            case 'company_deep_dive':
                history.push('/investment/company/22784')
                break;
            case 'about_us':
                history.push('/?scrollTo=about_us')
                break;
            case 'contact':
                history.push('/?scrollTo=contactus')
                break;
            case 'service':
                history.push('/?scrollTo=service')
                break;

            default:
                setMenuIndex(menu)
        }
    }
    return <div className={topHeaderClass}>
        <GridContainer>
            <GridItem xs={12} sm={12} md={12} lg={8} className={classes.tabs}>

                <div style={{ backgroundColor: menuIndex === 'home' ? commonStyles.successColor : 'transparent' }}>
                    <Button simple color='white' onClick={() => onClicMenu('home')} className={classes.topHeaderButton}>Home</Button>
                </div>
                <div style={{ backgroundColor: menuIndex === 'about_us' ? commonStyles.successColor : 'transparent' }}>
                    <Button simple color='white' onClick={() => onClicMenu('about_us')} className={classes.topHeaderButton}>About Us</Button>
                </div>
                <div style={{ backgroundColor: menuIndex === 'service' ? commonStyles.successColor : 'transparent' }}>
                    <Button simple color='white' onClick={() => onClicMenu('service')} className={classes.topHeaderButton}>Services</Button>
                </div>
                <div style={{ backgroundColor: menuIndex === 'contact' ? commonStyles.successColor : 'transparent' }}>
                    <Button simple color='white' onClick={() => onClicMenu('contact')} className={classes.topHeaderButton}>Contact</Button>
                </div>
                {/* <div style={{ backgroundColor: menuIndex === 'thematic_investing' ? commonStyles.successColor : 'transparent' }}>
                    <Button simple color='white' onClick={() => onClicMenu('thematic_investing')} className={classes.topHeaderButton}>Thematic Investing</Button>
                </div>

                <div style={{ backgroundColor: menuIndex === 'factor_investing' ? commonStyles.successColor : 'transparent' }}>
                    <Button simple color='white' onClick={() => onClicMenu('factor_investing')} className={classes.topHeaderButton}>Factor Analysis</Button>
                </div>
                <div style={{ backgroundColor: menuIndex === 'company_deep_dive' ? commonStyles.successColor : 'transparent' }}>
                    <Button simple color='white' onClick={() => onClicMenu('company_deep_dive')} className={classes.topHeaderButton}>Company Deep Dive</Button>
                </div> */}

            </GridItem>
        </GridContainer>
    </div>;
}
const mapStatsToProps = ({ user }) => ({ user });
export default connect(mapStatsToProps)(
    withStyles(styles, { withTheme: true })(TopHeader)
);